import React from "react";
import { graphql } from "gatsby";
import "normalize.css";
import GlobalStyles from "../styles/GlobalStyles.js";
import Header from "../components/Header.js";
import styled from "styled-components";
import PageIntro from "../components/PageIntro.js";
import Contact from "../sections/Contact.js";
import FloatingWhatsapp from "../components/FloatingWhatsapp.js";
import { Link } from "gatsby";

const BlogTemplate = ({ data }) => {
  const post = data.markdownRemark;

  return (
    <>
      <GlobalStyles />

      <Header hero={false} />
      <section id="intro">
        <div className="container">
          <PageIntro heading={post.frontmatter.title} />

          <img src={"/" + post.frontmatter.featured_image} alt="" />

          <div dangerouslySetInnerHTML={{ __html: post.html }} />

          <Link
            to="/news"
            className="btn"
            style={{ display: "inline-block", margin: "10px auto 0" }}
          >
            Back to news
          </Link>
        </div>
      </section>

      <Contact inverted={true} />
      <FloatingWhatsapp />
    </>
  );
};

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        featured_image
      }
    }
  }
`;

export default BlogTemplate;
